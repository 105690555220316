<template>
  <div class="app-context expanded">
    <app-header :headerOption="headerOption" ref="appheader"></app-header>
    <div class="context modify">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="join document">
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">수신병원</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="form.receive.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진료의사</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="form.doctor.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진단코드</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="진단코드를 입력해주세요."
              v-model="form.code.value"
              @keyup="checkCode()"
              @blur="checkCode()"
              maxlength="6"
            />
            <p class="warn-message" v-if="form.code.hasError">
              {{ form.code.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진료과목</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="진료과목을 입력해주세요."
              v-model="form.subject.value"
              @keyup="checkSubject()"
              @blur="checkSubject()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.subject.hasError">
              {{ form.subject.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">환자명</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="form.name.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">생년월일</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="form.birth.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">성별</h5>
          </div>
          <div class="radio-input-wrap">
            <div class="item">
              <input
                type="radio"
                id="male"
                v-model="form.gender.value"
                value="male"
                :disabled="form.gender.value !== ''"
              />
              <label for="male">남성</label>
            </div>
            <div class="item">
              <input
                type="radio"
                id="female"
                v-model="form.gender.value"
                value="female"
                :disabled="form.gender.value !== ''"
              />
              <label for="female">여성</label>
            </div>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">연락처</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="form.phone.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진단명 또는 주증상</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="진단명 또는 주증상을 입력해주세요."
              v-model="form.diagnosis.value"
              @keyup="checkDiagnosis()"
              @blur="checkDiagnosis()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.diagnosis.hasError">
              {{ form.diagnosis.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">환자상태 또는 의뢰내용</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="환자상태 또는 의뢰내용을 입력해주세요."
              v-model="form.vital.value"
              @keyup="checkVital()"
              @blur="checkVital()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.vital.hasError">
              {{ form.vital.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article last">
          <div class="title-block">
            <h5 class="title">첨부파일</h5>
          </div>
          <div class="attachment-wrap activate">
            <div class="attachment viewer">
              <div
                class="a-input-wrap"
                @click="download()"
                :class="{
                  active:
                    this.GET_REFER_DETAIL.referAttchNm ===
                    this.form.attachment.filename,
                }"
              >
                <span
                  class="file-name"
                  v-text="form.attachment.filename"
                ></span>
                <input
                  class="hidden file-upload"
                  type="file"
                  @click.stop
                  @change="onFileChange($event)"
                />
              </div>
              <div class="a-btn-wrap">
                <button type="button" @click="attachmentFile()">
                  파일첨부
                </button>
              </div>
              <div
                class="a-btn-wrap delete"
                v-if="form.attachment.filename !== ''"
              >
                <button type="button" @click="deleteFile()">삭제</button>
              </div>
            </div>
          </div>
        </div>
        <div class="store-btn-wrap">
          <button type="button" class="prev" @click="goPrev()">이전으로</button>
          <button type="button" class="active" @click="hyperPdf()">출력</button>
          <button
            type="button"
            :class="{
              active:
                form.diagnosis.flag &&
                form.vital.flag &&
                form.subject.flag &&
                form.code.flag,
            }"
            @click="next()"
          >
            정보저장
          </button>
        </div>
      </div>
    </div>
    <app-footer></app-footer>

    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import Directive from "@/components/join/Directive";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    AppHeader,
    AppFooter,
    Directive,
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      directiveInfo: {
        title: "진료의뢰서 변경",
        isTitleNewLine: false,
        content:
          "진료의뢰서 변경에 필수적인 내용입니다.\n수정 후 변경완료 버튼을 누르면 적용됩니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      form: {
        userData: {
          drId: "",
          drNm: "",
          hospNm: "",
        },
        diagnosis: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진단명을 입력해주세요.",
        },
        vital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*환자상태를 입력해주세요.",
        },
        receive: {
          value: "",
        },
        doctor: {
          value: "",
        },
        subject: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료과목을 입력해주세요.",
        },
        code: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진단코드를 입력해주세요.",
        },
        name: {
          value: "",
        },
        birth: {
          value: "",
        },
        gender: {
          value: null,
        },
        phone: {
          value: "",
        },
        attachment: {
          flag: false,
          title: "병원 로고",
          source: "",
          url: "",
          filename: "",
          isDeleted: 0,
        },
        agreeId: {
          value: "",
        },
        rcvDrid: {
          value: "",
        },
      },
      popupSet: {},
    };
  },
  created() {
    //console.log("referId Check :", this.GET_REFER_ID);
    this.bringDetail();
  },
  mounted() {},
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("referList", ["GET_REFER_DETAIL"]),
    ...mapGetters("request", ["GET_REFER_ID"]),
    ...mapGetters("util", ["GET_PDF_URL"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("request", ["REFER_MODIFY"]),
    ...mapActions("referList", ["REFER_DETAIL"]),
    ...mapActions("util", ["BRING_PDF_URL_ON_REFER", "BRING_PDF"]),
    download() {
      if (
        this.GET_REFER_DETAIL.referAttchNm === this.form.attachment.filename &&
        this.GET_REFER_DETAIL.referAttchNm !== ""
      ) {
        window.open(
          "https://www.drrefer.net/api/pic/" +
            this.GET_REFER_DETAIL.referAttchId,
          "_blank"
        );
      }
    },
    async hyperPdf() {
      await this.callPdf();
      await this.fileCall();
    },
    async fileCall() {
      try {
        await this.BRING_PDF(this.GET_PDF_URL.fileName).then(() => {
          const blob = new Blob([this.GET_PDF_URL], {
            type: "application/pdf",
          });
          const result = URL.createObjectURL(blob);
          //console.log("찾기", result);
          const url = "https://www.drrefer.net/pdf/" + result;
          //console.log("called_Pdf_url", url);

          window.open(result, "_blank");
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async callPdf() {
      try {
        await this.BRING_PDF_URL_ON_REFER(
          this.GET_REFER_DETAIL.referId
        ).then(() => {});
      } catch (error) {
        console.log("error :", error);
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "PDF를 불러올 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    goPrev() {
      this.$router.push("/v/mypage/request/0").catch(()=>{});
    },
    onFileChange(event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      //console.log("가져올 파일명: ", getFile[0].name);
      //console.log("가져올 파일의 URL", getFileUrl);
      this.form.attachment.source = event.target.files[0];
      this.form.attachment.isDeleted = 0;
      /** getFile */
      this.form.attachment.url = getFileUrl;
      this.form.attachment.filename = getFile[0].name;
      this.form.attachment.flag = true;
      //console.log("flag", this.form.attachment.flag);
      //console.log("파일", event.target.files[0]);
      //console.log("소스", this.form.attachment.source);
    },
    deleteFile() {
      this.form.attachment.isDeleted = 1;
      this.form.attachment.flag = false;
      this.form.attachment.source = null;
      this.form.attachment.url = "";
      this.form.attachment.filename = "";
      document.querySelector("input.file-upload").value = "";
    },
    attachmentFile() {
      const parentNode = document.querySelector(".j-article.last");
      //console.log("parentNode", parentNode);
      parentNode.querySelector("input.file-upload").click();
    },
    async bringDetail() {
      //console.log("사전정보 체크", this.GET_REFER_ID);
      if (this.GET_REFER_ID !== null) {
        try {
          await this.REFER_DETAIL(this.GET_REFER_ID).then(() => {
            //console.log("API성공");
            //console.log("감지", this.GET_REFER_DETAIL);
            this.form.rcvDrid.value = this.GET_REFER_DETAIL.rcvDrId;
            this.form.userData.drId = this.GET_REFER_DETAIL.sndDrId;
            this.form.userData.drNm = this.GET_REFER_DETAIL.sndDrNm;
            this.form.userData.hospNm = this.GET_REFER_DETAIL.sndHospNm;
            this.form.receive.value = this.GET_REFER_DETAIL.rcvHospNm;
            this.form.doctor.value = this.GET_REFER_DETAIL.rcvDrNm;
            this.form.code.value = this.GET_REFER_DETAIL.diagCd;
            this.form.subject.value = this.GET_REFER_DETAIL.rcvParts;
            this.form.name.value = this.GET_REFER_DETAIL.patNm;
            this.form.birth.value = this.GET_REFER_DETAIL.patBirth;
            this.form.gender.value =
              this.GET_REFER_DETAIL.patSex === "0" ? "female" : "male";
            this.form.phone.value = this.GET_REFER_DETAIL.patTelno;
            this.form.agreeId.value = this.GET_REFER_DETAIL.agreeId;
            this.form.diagnosis.value = this.GET_REFER_DETAIL.diagNm;
            this.form.vital.value = this.GET_REFER_DETAIL.referConts;
            this.form.diagnosis.flag = true;
            this.form.vital.flag = true;
            this.form.subject.flag = true;
            this.form.code.flag = true; 
            this.form.attachment.source =
              this.GET_REFER_DETAIL.referAttchId !== null &&
              this.GET_REFER_DETAIL.referAttchId !== ""
                ? this.GET_REFER_DETAIL.referAttchId
                : "";
            this.form.attachment.filename =
              this.GET_REFER_DETAIL.referAttchNm !== null &&
              this.GET_REFER_DETAIL.referAttchNm !== ""
                ? this.GET_REFER_DETAIL.referAttchNm
                : "";
          });
        } catch (error) {
          console.log("error :", error);
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 상세내용을 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = " 상세내용을 불러올 수 없습니다.";
        this.popupSet.popType = "custom";
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.destination = true;
        this.popupSet.nextLink = "/v/mypage/request/0";
      }
    },
    checkVital() {
      this.form.vital.flag = false;
      this.form.vital.hasError = true;
      const Vital = this.form.vital.value.replace(/\s/gi, "");
      if (Vital === "") {
        this.form.vital.flag = false;
        this.form.vital.errorTxt = "*환자상태를 입력해주세요.";
        return false;
      }
      const isVital = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isVital.test(Vital)) {
        this.form.vital.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.vital.flag = false;
        return false;
      }
      this.form.vital.flag = true;
      this.form.vital.hasError = false;
      this.form.vital.errorTxt = "";
      return true;
    },
    checkDiagnosis() {
      this.form.diagnosis.flag = false;
      this.form.diagnosis.hasError = true;
      const Diagnosis = this.form.diagnosis.value.replace(/\s/gi, "");
      if (Diagnosis === "") {
        this.form.diagnosis.flag = false;
        this.form.diagnosis.errorTxt = "*진단명을 입력해주세요.";
        return false;
      }
      const isDiagnosis = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isDiagnosis.test(Diagnosis)) {
        this.form.diagnosis.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.diagnosis.flag = false;
        return false;
      }
      this.form.diagnosis.flag = true;
      this.form.diagnosis.hasError = false;
      this.form.diagnosis.errorTxt = "";
      return true;
    },
    checkSubject() {
      this.form.subject.flag = false;
      this.form.subject.hasError = true;
      const Subject = this.form.subject.value.replace(/\s/gi, "");
      if (Subject === "") {
        this.form.subject.flag = false;
        this.form.subject.errorTxt = "*진료과목을 입력해주세요.";
        return false;
      }
      const isSubject = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isSubject.test(Subject)) {
        this.form.subject.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.subject.flag = false;
        return false;
      }
      this.form.subject.flag = true;
      this.form.subject.hasError = false;
      this.form.subject.errorTxt = "";
      return true;
    },
    checkCode() {
      this.form.code.flag = false;
      this.form.code.hasError = true;
      const Code = this.form.code.value.replace(/\s/gi, "");
      if (Code === "") {
        this.form.code.flag = false;
        this.form.code.errorTxt = "*진단코드를 입력해주세요.";
        return false;
      }
      const isCode = /^[0-9]{4,6}$/;
      if (!isCode.test(Code)) {
        this.form.code.errorTxt = "*-을 제외한 숫자만 입력할 수 있습니다.";
        this.form.code.flag = false;
        return false;
      }
      this.form.code.flag = true;
      this.form.code.hasError = false;
      this.form.code.errorTxt = "";
      return true;
    },
    initFlag() {},
    checkFlag() {
      if (
        this.form.userData.drId === "" ||
        !this.form.diagnosis.flag ||
        !this.form.vital.flag ||
        !this.form.subject.flag ||
        !this.form.code.flag
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.diagnosis.flag) {
          this.form.diagnosis.hasError = true;
        }
        if (!this.form.vital.flag) {
          this.form.vital.hasError = true;
        }
        if (!this.form.subject.flag) {
          this.form.subject.hasError = true;
        }
        if (!this.form.code.flag) {
          this.form.code.hasError = true;
        }
        return false;
      }
      return true;
    },
    async next() {
      if (this.checkFlag()) {
        const referParam = {
          rcvDrId: this.form.rcvDrid.value,
          sndDrId: this.form.userData.drId,
          sndDrNm: this.form.userData.drNm,
          sndHospNm: this.form.userData.sndHospNm,
          rcvHospNm: this.form.receive.value,
          rcvDrNm: this.form.doctor.value,
          diagCd: this.form.code.value,
          rcvParts: this.form.subject.value,
          patNm: this.form.name.value,
          patBirth: this.form.birth.value,
          patSex: this.form.gender.value === "female" ? 0 : 1,
          patTelno: this.form.phone.value,
          diagNm: this.form.diagnosis.value,
          referConts: this.form.vital.value,
          agreeId: this.form.agreeId.value,
          referAttchId: this.GET_REFER_DETAIL.referAttchId,
          /**referAttchId */
          referId: this.GET_REFER_DETAIL.referId,
          isDeletedAttchId: this.form.attachment.isDeleted,
        };
        //console.log("referParam", referParam);

        // 유효성 검사
        // 1. 진단의뢰번호
        if(referParam.referId === null 
        || referParam.referId === undefined)
        referParam.referId = "";
        
        var referIdBoo = /^[0-9]{8,14}$/;

        if(!referIdBoo.test(referParam.referId)){
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
        }

        // 2. 수신자 아이디
        if(referParam.rcvDrId === null 
        || referParam.rcvDrId === undefined)
        referParam.rcvDrId = "";
        
        var rcvDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

        if(!rcvDrIdBoo.test(referParam.rcvDrId)){
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 3. 수신자명
        if(referParam.rcvDrNm === null 
        || referParam.rcvDrNm === undefined)
        referParam.rcvDrNm = "";
        
        var rcvDrNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{1,100}$/;

        if(!rcvDrNmBoo.test(referParam.rcvDrNm.replace(/\s/gi,""))){
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 4. 진료과목
        if(referParam.rcvParts === null 
        || referParam.rcvParts === undefined)
        referParam.rcvParts = "";
        
        if(referParam.rcvParts.length < 0 
        || referParam.rcvParts.length > 100){
          this.SET_POPUP(true);
          this.popupSet.title = "진료과목 확인";
          this.popupSet.content = "진료과목의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // // 5. 동의번호
        // if(referParam.agreeId === null 
        // || referParam.agreeId === undefined)
        // referParam.agreeId = "";
        
        // var agreeIdBoo = /^[0-9]{8,14}$/;

        // if(!agreeIdBoo.test(referParam.agreeId)){
        //     this.SET_POPUP(true);
        //     this.popupSet.title = "서버요청 실패";
        //     this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
        //     this.popupSet.popType = "warn";
        //     this.popupSet.cancelBtnText = "확인";
        //     return;
        // }

        // 6. 환자명
        if(referParam.patNm === null 
        || referParam.patNm === undefined)
        referParam.patNm = "";
        var patNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,20}$/;

        if(!patNmBoo.test(referParam.patNm.replace(/\s/gi,""))){
          this.SET_POPUP(true);
          this.popupSet.title = "환자명 확인";
          this.popupSet.content = "환자명의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 7. 환자생년월일
        if(referParam.patBirth === null 
        || referParam.patBirth === undefined)
        referParam.patBirth = "";
        
        var patBirthBoo = /^[0-9]{0,8}$/;

        if(!patBirthBoo.test(referParam.patBirth.replace(/-/gi,""))){
          this.SET_POPUP(true);
          this.popupSet.title = "환자생년월일 확인";
          this.popupSet.content = "환자생년월일의 숫자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 8. 환자성별
        if(referParam.patSex === null 
        || referParam.patSex === undefined)
        referParam.patSex = "";
        
        var patSexBoo = /^[0-9]{0,1}$/;

        if(!patSexBoo.test(referParam.patSex)){
          this.SET_POPUP(true);
          this.popupSet.title = "환자성별 확인";
          this.popupSet.content = "환자성별을 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 9. 환자 전화번호
        if(referParam.patTelno === null 
        || referParam.patTelno === undefined)
        referParam.patTelno = "";
        
        var patTelnoBoo = /^[0-9]{8,14}$/;

        if(!patTelnoBoo.test(referParam.patTelno.replace(/-/gi,""))){
          this.SET_POPUP(true);
          this.popupSet.title = "환자 전화번호 확인";
          this.popupSet.content = "환자 전화번호를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 9. 발신 아이디
        if(referParam.sndDrId === null 
        || referParam.sndDrId === undefined)
        referParam.sndDrId = "";
        
        var sndDrIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

        if(!sndDrIdBoo.test(referParam.sndDrId)){
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 10. 진단코드
        if(referParam.diagCd === null 
        || referParam.diagCd === undefined)
        referParam.diagCd = "";
        
        var diagCdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,20}$/;

        if(!diagCdBoo.test(referParam.diagCd)){
          this.SET_POPUP(true);
          this.popupSet.title = "진단코드 확인";
          this.popupSet.content = "진단코드의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 11. 진단명/주증상명
        if(referParam.diagNm === null 
        || referParam.diagNm === undefined)
        referParam.diagNm = "";

        if(referParam.diagNm.length < 0 
        || referParam.diagNm.length > 200){
          this.SET_POPUP(true);
          this.popupSet.title = "진단명/주증상명 확인";
          this.popupSet.content = "200자 이내의 진단명/주증상명을 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 12. 환자상태/의뢰내용
        if(referParam.referConts === null 
        || referParam.referConts === undefined)
        referParam.referConts = "";

        if(referParam.referConts.length < 0 
        || referParam.referConts.length > 3000){
          this.SET_POPUP(true);
          this.popupSet.title = "환자상태/의뢰내용 확인";
          this.popupSet.content = "3000자 이내의 환자상태/의뢰내용을 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 13. 의뢰첨부파일번호
        if(referParam.referAttchId === null 
        || referParam.referAttchId === undefined)
        referParam.referAttchId = "";

        if(referParam.referAttchId.length < 0 
        || referParam.referAttchId.length > 40){
          this.SET_POPUP(true);
          this.popupSet.title = "의뢰첨부파일번호 오류";
          this.popupSet.content = "첨부파일을 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        const transedReferParam = JSON.stringify(referParam);
        const transData = new FormData();
        transData.append("referParam", transedReferParam);
        transData.append(
          "referFile",
          this.form.attachment.source !== "" &&
            this.form.attachment.source !== null
            ? this.form.attachment.source
            : this.GET_REFER_DETAIL.referAttchId
        );
        try {
          await this.REFER_MODIFY(transData).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "진료의뢰서 변경완료";
            this.popupSet.content = "진료의뢰서를 성공적으로 변경하였습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/v/mypage/request/0";
            this.popupSet.destination = true;
          });
        } catch (error) {
          console.log("error: ", error);
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 진료의뢰서 변경을 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        console.log("체크 상태확인");
      }
    },
  },
  watch: {},
  destroyed() {
    this.SET_POPUP(false);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
.viewer {
  position: relative;
  max-width: 645px;
  &.noneReceive {
    &:after {
      content: "클릭하여 수신병원을 선택해주세요.";
      color: #b7b7b7;
    }
  }
}
</style>
